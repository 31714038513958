<template>
  <custom-drop-down-menu @eventMenu="eventMenu">
    <template #header>
      <div class="header-filter-immo" :class="{ active: actionMenu }">
        <div class="flex-center">
          <div :title="header.text" class="flex-center">
            <div class="header-title mr-2">
              {{ header.text }}
            </div>
            <div>
              <v-icon>mdi-filter-outline</v-icon>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #body v-if="options && options.length">
      <div
        class="radio-filter-mul-immo"
        v-if="computedOptions && computedOptions.length"
      >
        <v-checkbox
          @change="handelInputRadio"
          v-for="item in computedOptions"
          :key="item.id"
          v-model="filterValue"
          :label="item[label]"
          color="#704ad1"
          :value="item"
          hide-details
        ></v-checkbox>
      </div>
      <div v-else class="font-text text-capitalize font-sz-12  text-center">
        {{ $t('noResult') }}
      </div>
      <v-divider class="pb-3 mt-2"></v-divider>
      <v-text-field
        class="field-search-filter-mul-immo"
        v-model="search"
        :append-icon="'mdi-magnify'"
        outlined
        hide-details
        single-line
        :label="$t('search')"
      ></v-text-field>
    </template>
  </custom-drop-down-menu>
</template>

<script>
import customDropDownMenu from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/component/customDropDownMenu.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  watch: {
    filterContact(value) {
      this.filterValue = value ? value : []
    }
  },
  props: {
    header: { required: true },
    filterContact: { required: true }
  },
  data() {
    return {
      loading: false,
      search: null,
      actionMenu: false,
      filterValue: this.filterContact ? this.filterContact : [],
      options: [],
      label: 'contact_id'
    }
  },
  computed: {
    ...mapGetters(['getProjectRouteDynamic']),
    computedOptions() {
      return this.search
        ? this.options.filter(item =>
            item[this.label].toLowerCase().includes(this.search.toLowerCase())
          )
        : this.options
    },
    computedTitle() {
      let selected = this.filterValue.length
        ? ' : ' + this.filterValue[0][this.label]
        : ` : ${this.$t('any')}`

      let athor =
        this.filterValue.length && this.filterValue.length > 1
          ? ' +' + (this.filterValue.length - 1)
          : ''
      return selected + athor
    }
  },
  methods: {
    ...mapActions(['fetchProjectRouteDynamic']),
    async handelInputRadio() {
      this.$emit('handelFilter', this.filterValue)
    },
    eventMenu(event) {
      this.actionMenu = event
    }
  },
  components: { customDropDownMenu },
  async mounted() {
    if (this.header.type == 'select') {
      if (this.header.links) {
        await this.fetchProjectRouteDynamic({ route: this.header.links })
        if (this.getProjectRouteDynamic && this.getProjectRouteDynamic.length) {
          this.options = this.getProjectRouteDynamic
        }
      }
    }
  }
}
</script>

<template>
  <div class="immo-data-table" :style="'width:' + width">
    <!-- table  -->
    <v-data-table
      :items-per-page="computedTableBoard.length"
      :columnDefs="[{ width: 200, targets: 0 }]"
      :fixedColumns="true"
      v-model="selected"
      show-select
      :headers="computedHeaderColumnTable"
      :items="computedTableBoard"
      hide-default-footer
      class="table-projet-immo"
      :class="{
        'scroll-table-proposals': styleComponents == 'proposals',
        'scroll-table-modal-proposals': styleComponents == 'tableModalProposals'
      }"
      :fixed-header="true"
      :no-data-text="Loader ? $t('loading_msg') : $t('no_data_table')"
      item-key="id"
      :single-select="false"
      @click:row="handleSelectedRow"
      :reset-checkbox="true"
    >
      <!-- HEADER TABLE -->
      <template
        v-for="header in computedHeaderColumnTable"
        v-slot:[`header.${header.key}`]="{}"
      >
        <!-- Afficher le composant FilterHeader si iconFilter est vrai -->
        <FilterHeader
          v-if="iconFilter && filterContact && filterContact.length"
          :key="'header-name-' + header.text"
          :header="header"
          @handelFilter="handelColumnFilter"
          :filterContact="filterContact"
        ></FilterHeader>
        <!-- Afficher le texte de l'en-tête par défaut si iconFilter est faux -->
        <div v-else :key="'header-name-' + header.text">{{ header.text }}</div>
      </template>

      <!-- ACTIONS DOWNLOAD -->
      <template v-slot:[`item.download`]="{ item }">
        <font-awesome-icon
          @click="() => $emit('download', item)"
          :icon="['fas', 'download']"
          class="icon-download"
        />
      </template>
      <!-- ACTIONS -DELETED -->
      <template v-slot:[`item.deleted`]="{ item }">
        <v-btn icon fab @click.prevent.stop="() => $emit('deleted', item)">
          <font-awesome-icon :icon="['fas', 'trash']" class="font-sz-20" />
        </v-btn>
      </template>
    </v-data-table>
    <!-- footer  -->

    <v-row class="row-footer">
      <v-col class="total-footer">
        <span>
          {{ $t('total') }}
          <span v-if="perPage">{{ $t('projet') }}</span> :
          <span v-if="perPage"
            >{{ 50 * page >= Total ? Total : 50 * page }}/ {{ Total }}</span
          >
        </span>
      </v-col>
      <v-col class="pagination-footer">
        <v-pagination
          v-model="page"
          :length="computedLastPage"
          @input="pagination"
          :total-visible="4"
        ></v-pagination
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilterHeader from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/FilterHeaderTable/FilterHeader.vue'
export default {
  components: { FilterHeader },

  props: {
    Loader: { required: false, default: false },
    Total: { required: false, default: null },
    perPage: { required: false, default: null },
    computedHeaderColumnTable: { required: true },
    computedTableBoard: { required: true },
    computedLastPage: { required: true },
    computedRestPage: { required: true },
    resetCheckbox: { required: false },
    styleComponents: { required: false },
    showActionHeaderEditable: { required: false },
    width: { defaut: '100%' },
    UpdateViewTable: { default: () => {} },
    iconFilter: { required: false, defaut: false }, // Ajoute une icône de filtre dans l'en-tête de la colonne
    filterContact: { required: false },
    pagePagination: { required: false }
  },
  data() {
    return {
      selected: [],
      anIncreasingNumber: 1,
      page: this.pagePagination ? this.pagePagination : 1,
      selectAll: false,
      openModalMasseProjet: false, // Not yet
      openModalFileProjet: false
    }
  },
  computed: {
    ...mapGetters(['getCurentProjectType']),
    dateValue() {
      return function(value) {
        return value.split(' ')[0]
      }
    },
    timeValue() {
      return function(value) {
        return value.staskplit(' ')[1]
      }
    }
  },

  methods: {
    ...mapActions(['fetchSingleProject']),
    handelColumnFilter(filter) {
      this.$emit('handelHeaderFilter', filter)
    },
    initialize() {
      this.computedTableBoard
    },
    //Event Focus In Project
    async handleSelectedRow(projet) {
      this.$emit('openModalFileProjet', projet)
    },

    //Pagination
    pagination(paginate) {
      this.page = paginate
      this.$emit('pagination', this.page)
    }
  },
  watch: {
    computedRestPage() {
      this.page = 1
    },
    selected(arg) {
      this.$emit('SlectedItems', arg)
    },
    resetCheckbox() {
      // Réinitialiser les cases à cocher si la valeur de resetCheckbox est vraie
      this.selected = []
    }
  }
}
</script>

<style lang="scss">
.immo-data-table {
  // width: calc(100% - 260px);
  .table-projet-immo {
    width: 100%;
    background: transparent;
    thead {
      .v-data-table-header__icon {
        position: absolute !important;
        font-size: 20px !important;
        right: 5px !important;
        top: 15px !important;
      }

      .header-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        width: 82%;
      }
    }
    tbody {
      background: #fff;
      td {
        height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 248px;
        min-width: 50px;
        // max-width: 120px;
        width: 120px;
        font-size: 11px !important;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #2f2f2f;
        padding: 0px 3px 0px 3px !important;
        cursor: pointer;
        // min-width: 120px;
      }
    }
    th {
      color: #2f2f2f !important;

      font-size: 13px !important;
      font-weight: 600 !important;
      line-height: 14px !important;
      letter-spacing: 0em !important;
      text-align: left !important;
      text-transform: capitalize !important;
      background: #f7f5fd !important;
      padding: 0px 3px 0px 3px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .v-data-table__wrapper {
      height: calc(100vh - 237px);
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #bdbdbd;
        cursor: pointer !important;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: 0px 1px 0px 0px #0000001f inset;
        box-shadow: 0px 1px 0px 0px #0000001f inset;
        border-radius: 4px;
        background-color: #eeeeee;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 5px;
      }
    }
    &.scroll-table-proposals {
      .v-data-table__wrapper {
        height: calc(100vh - 481px);
      }
    }
    &.scroll-table-modal-proposals {
      .v-data-table__wrapper {
        height: auto;
      }
    }
    .v-data-table__empty-wrapper {
      text-align-last: center;
    }
  }
  .row-footer {
    background: #fff;
    margin: 0px;
    margin-top: 6px;
    .total-footer {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #212121;
    }
    .pagination-footer {
      padding: 1px;
      .v-pagination {
        justify-content: flex-end;
      }
      .v-pagination__item,
      .v-pagination__navigation,
      .v-pagination__more {
        height: 28px;
        width: 28px;
        min-width: 28px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
      }
      .v-pagination__navigation--disabled {
        background: #e6e6e6 !important;
        i {
          color: #b4b0c9 !important;
        }
      }
      .v-pagination__item,
      .v-pagination__more {
        color: #45148f;
        border: 1px solid #e9f6fa;
        background: #fff;
      }
      .v-pagination__more {
        align-items: normal;
        font-size: 19px !important;
        position: relative;
      }
      .v-pagination__more::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 11px 0px 0px;
        border-color: transparent #45148f transparent transparent;
        border-radius: 5px;
      }
      .v-pagination__item--active {
        background-color: #45148f !important;
        color: #f2f2f2 !important;
        border: none !important;
      }
      .v-pagination__navigation {
        background: #45148f;
        color: #f2f2f2;
      }
      .v-pagination__navigation,
      .v-pagination__item--active {
        border: none;
        i {
          color: #f2f2f2;
        }
      }
    }
  }
}
</style>
